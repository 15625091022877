#resume{
    font-size: 40px;
}


#social{
    position: absolute;
}

@media (max-width: 1000px){
    #resume p{
        font-size: 20px;
    }

}
/*
left: 50%;
    transform: translateX(-50%);
    */


.logo{
    width: 20px;
    filter:opacity(60%);
}

.logo-2{
    width: 30px;
    filter:opacity(60%);
}


.logo-1{
    position: absolute;
    bottom: 5%;
    width: 40%;
    right: 60px;
    filter: opacity(60%);
    
}

#link-img{
    cursor: pointer;
}

#links{
    position: absolute;
    bottom: 0;
}


#links-container{
    animation: aparition-an 1s;
    width: 170px;
    height: 100px;
    backdrop-filter: blur(14px) brightness(105%);
    
}

.btn-close{
    width: 2px;
    height: 2px;
}

#modal-close-button{
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    margin: 10px;
}